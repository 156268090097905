.select {
  width: 100%;
  position: relative;
  &__input {
    width: 100%;
    height: 4rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 10px;
    color: #333;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #D3D3D3;
    &_state {
      &_error {
        border-color: red;
        background: #ffeded;
      }
    }
  }

  &__placeholder {
    max-width: 380px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
    font-size: 1.4rem;
    &_selected {
      font-weight: 600;
    }
    &_empty {
      color: #AFAFAF;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  &__arrow {
    font-size: 15px;
    position: relative;
    &_closed {
      top: 2px;
      transform: rotate(0deg);
    }
    &_opened {
      top: -1px;
      transform: rotate(-180deg);
    }
    svg {
      fill: #b6b6b6;
    }
  }

  &__loading {
    font-size: 30px;
    position: relative;
    right: -8px;
  }

  &__dropdown {
    position: absolute;
    z-index: 1010;
    width: 100%;
    padding: 5px;
    opacity: 0;
    background: #fff;
    max-height: 220px;
    pointer-events: none;
    overflow: hidden auto;
    transform: translateY(5%);
    transition: all .15s ease-in;
    border-radius: .5rem;
    box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      border-radius: 100px;
      background-clip: content-box;
      background-color: #197323;
    }

    &_opened {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0%);
      transition: all .15s ease-in;
    }
  }
}


.options {
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__item {
    height: 3.5rem;
    cursor: pointer;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: .5rem;
    &:hover {
      background: #ebebeb;
    }
  }
  &__label {
    max-width: 380px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
    font-size: 1.4rem;
    font-weight: 600;
  }
  &__selected {
    img {
      width: 15px;
      top: 2px;
      position: relative;
    }
  }
  &__create {
    margin-top: 5px;
    button {
      width: 100%;
    }
  }
}
