.group {
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 1.25rem;
  &_flex {
    display: flex;
    justify-content: space-between;
  }
}
