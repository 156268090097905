.bank {
  padding: 2rem 3rem 4rem;
  &__title {
    margin-bottom: 2rem;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
    h3 {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  &__form {
    button {
      margin-top: 2.5rem;
    }
  }
  &__items {
    padding: 1rem 1.5rem;
    border-radius: .5rem;
    margin-bottom: 2rem;
    border: 1px solid #ebebeb;
  }
  &__add {
    width: 100%;
  }
  &__item {
    cursor: pointer;
    background: #fff;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 1rem;
    }
    h4 {
      font-weight: 400;
      font-size: 1.3rem;
    }
  }
}
