@import "./src/variable";

.form {
  width: 100%;
  padding: 10px 30px 30px;
  &__loading {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
  }
  &__title {
    margin-bottom: 3rem;
  }
  &__error {
    margin-top: 1rem;
  }
}

.Container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.6);
}

.Wrapper {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5rem 1rem;
  overflow-y: auto;
  height: 100%;

  @include tablet {
    padding: 10rem 1rem;
  }
}

.Item {
  width: 95%;
  margin:  0 auto;
  position: relative;
  overflow: hidden;
  padding:  2rem 2.5rem;
  background: #fff;
  @include tablet {
    width: 45rem;
    padding:  3rem 4.5rem;
  }
  h1 {
    margin: 0;
    color: #197323;
    font-size: 2rem;
    margin-bottom: .5rem;
    @include tablet {
      font-size: 2.4rem;
    }
  }
  img {
    margin:  0 auto;
  }
  p {
    font-size: 1.3rem;
    color: #333;
    @include tablet {
      font-size: 1.4rem;
    }
  }
}

.Device {
  position: relative;
  width: 95%;
  padding:  0;
  @include tablet {
    width: 55rem;
  }
}

.Form {
  margin-top: 3rem;
  width: 100%;
  > img {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
  }
  > h1 {
    margin-bottom: 2rem;
  }
}

.FormLoading {
  div {
    opacity: 0.1;
    pointer-events: none;
  }
  img {
    opacity: 1;
  }
}


.FormButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormSubmit {
  cursor: pointer;
  padding: 0 2.5rem;
  height: 4rem;
  background: #197323;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: .5rem;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}

.FormReset {
  outline: none;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  color: #C22B00;
  text-decoration: underline;
  background: none;
  &:hover {
    text-decoration: none;
  }
}

.FormRegister {
  outline: none;
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  color: #197323;
  text-decoration: underline;
  background: none;
  &:hover {
    text-decoration: none;
  }
}

.ButtonClose {
  cursor: pointer;
  padding: 0 2.5rem;
  margin:  0 auto;
  height: 4rem;
  background: #197323;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: .5rem;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}

.Notice {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h1 {
    color: #333;
    font-size: 1.8rem;
    margin-top: 2.5rem;
    white-space: pre-line;
    @include tablet {
      font-size: 2.4rem;
    }
  }
  p {
    margin: 1.5rem 0 3rem;
    line-height: 140%;
    white-space: pre-line;
  }
  span {
    display: block;
    margin-top: 1rem;
    color: #9a4343;
    font-size: 1.2rem;
  }
  button {
    padding:  1.25rem 3rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
    background: #197323;
    box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
    border-radius: 5px;
  }
}

.NoticeWrapper {
  padding:  6rem 2rem;
  @include tablet {
    padding:  4rem 2rem;
  }
}

.GeneralError {
  color: red;
  display: block;
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.StepInformer {
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  background: #F2F2F2;
  @include tablet {
    padding: 2rem 3rem;
  }
}
.StepInformerItem {
  width: 47%;
  opacity: .3;
  @include tablet {
    width: 45%;
  }
  h2 {
    font-size: 1.6rem;
    color: #197323;
    @include tablet {
      font-size: 1.8rem;
    }
  }
  p {
    font-size: 1.2rem;
    color: #333;
    margin: 1rem 0;
    @include tablet {
      font-size: 1.3rem;
    }
  }
  span {
    display: block;
    width: 100%;
    background: #888;
    height: .4rem;
  }
}

.StepInformerActive {
  opacity: 1;
  span {
    background: #197323;
  }
}

.StepWrapper {
  position: relative;
  overflow: hidden;
  form {
    padding: 2rem 0;
    margin-top: 0;
    @include tablet {
      padding: 2rem 0;
    }
  }
}

.StepItem {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  @include tablet {
    padding: 0 3rem;
  }
}


.PayWrapper {
  padding:  2rem 0;
  h1 {
    margin-bottom: 2rem;
  }
}

.PayRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: .75rem;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 1rem;
  p {
    &:first-child {
      font-weight: 600;
    }
  }
}

.PayBottom {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin: 0!important;
    width: 10rem;
  }
}

.PayManager {
  display: flex;
  margin-top: 2rem;
  p {
    line-height: 140%;
    font-size: 1.6rem;
    text-align: center;
    b {
      color: #197323;
    }
  }
}
