@import "./src/variable";

.Group {
  position: relative;
  display: block;
  margin-bottom: 1.25rem;
}
.Relative {
  position: initial!important;
}
.GroupFlex {
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  > div {
    width: 100%;
    @include tablet {
      width: 32%;
      margin-bottom: 0;
    }
  }
}

.Label {
  cursor: pointer;
  display: block;
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: .5rem;
  @include tablet {
    font-size: 1.4rem;
  }
}

.Input {
  width: 100%;
  color: #333;
  height: 4rem;
  font-size: 1.3rem;
  font-weight: 600;
  background: #fff;
  padding-left: 1.25rem;
  border: 1px solid #D3D3D3;
  border-radius: .5rem;
  @include tablet {
    font-size: 1.4rem;
  }
  &::placeholder {
    font-size: 1.2rem;
    color: #AFAFAF;
    @include tablet {
      font-size: 1.3rem;
    }
  }
  &:focus {
    border: 2px solid #197323;
  }
  &:disabled {
    cursor: no-drop;
    background: #ebebeb;
  }
}

.Message {
  width:  100%;
  display: block;
  color: red;
  padding-top: .5rem;
  font-size: 1.2rem;
  @include tablet {
    font-size: 1.2rem;
  }
}

.Error .Message {
  display: block;
}

.Error .Input {
  border: 1px solid #FF0000;
}

.Error .File {
  border: 1px solid #FF0000;
}

.ErrorSelect > div {
  border-color: #ff0000;
}


.File {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #AFAFAF;
  height: 4rem;
  font-size: 1.2rem;
  font-weight: 600;
  background: #fff;
  padding-left: 1.25rem;
  border: 1px solid #D3D3D3;
  border-radius: .5rem;
  @include tablet {
    font-size: 1.3rem;
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    background: #fff;
  }
  &:focus {
    border: 2px solid #197323;
  }
  &:disabled {
    cursor: no-drop;
    background: #ebebeb;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #222;
    padding: 0 1.5rem;
    background: #D3D3D3;
    @include tablet {
      padding: 0 2rem;
    }
    img {
      margin-right: 1rem;
      width: 1.85rem;
      opacity: .4;
    }
  }
}


.FileChanged {
  color: #333;
}


[type=file] {
  position: absolute;
  filter: alpha(opacity=0);
  opacity: 0;
}