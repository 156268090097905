.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 3000;
  svg {
    width: 70px;
    fill: #197323;
  }
}
