@import "./src/variable";

.Wrapper {
  width: 100%;
  overflow-x: auto;
  @include tablet {
    overflow-x: inherit;
  }
}


.Table {
  border-collapse: collapse;
  margin: 2rem 0 0 0;
  padding: 0;
  width: 90rem;
  @include tablet {
    width: 100%;
  }
}

.Thead {
  th {
    top: 0;
    z-index: 10;
    color: #fff;
    font-weight: 600;
    text-align: left;
    font-size: 1.3rem;
    position: sticky;
    padding: 1.25rem 1rem;
    background: #197323;
    border: 1px solid #164c1c;
    border-top: none;
    border-bottom: 2px solid #164c1c;
    @include tablet {
      font-size: 1.4rem;
      padding: 2rem 1.5rem;
    }
  }
}


.Tbody {
  td {
    margin: 0;
    border: 1px solid #d0cfcf;
    color: #000;
    line-height: 140%;
    font-size: 1.3rem;
    padding: 1.5rem 1rem;
    @include tablet {
      font-size: 1.4rem;
      padding: 1.8rem 1.5rem;
    }
  }
  tr {
    &:nth-child(odd) {
      background-color: #f1f2f3;
    }
    &:nth-child(even) {
      background-color: #e7ecee;
    }
  }
}

.Price,
.Name {
  font-weight: 800;
  color: #000;
}

.Brand {
  width: 340px;
}