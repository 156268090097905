.file {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &__uploader {
    width: 100%;
    height: 80px;
    display: flex;
    cursor: pointer;
    background: #fff;
    align-items: center;
    justify-content: center;
    border: 1px solid #D3D3D3;
    border-radius: .5rem;
    &_state {
      &_error {
        background: #fff;
        border-color: red;
      }
    }
  }

  &__initial {
    display: flex;
    color: #AFAFAF;
    font-size: 1.3rem;
    align-items: center;
    span {
      color: inherit;
    }
  }
  &__list {
    font-size: 1.3rem;
  }
}
