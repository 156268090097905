@import "./src/variable";

.Home {
  @include tablet {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 3rem;
  }
}

.Text {
  padding-bottom: 5rem;
  width: 100%;
  @include tablet {
    width: 465px;
  }
  h1 {
    font-size: 2.1rem;
    line-height: 2.8rem;
    color: #197323;
    @include tablet {
      font-size: 3.3rem;
      line-height: 4.5rem;
    }
  }
  p {
    margin-bottom: 1rem;
    max-width: 44rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #333;
    @include tablet {
      font-size: 1.4rem;
      line-height: 2.3rem;
    }
    br {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
}

.Image {
  width: 100%;
  display: block;
  @include tablet {
    width: calc(100% - 465px);
  }
  img {
    width: 100%;
  }
}

.Button {
  color: #fff;
  line-height: 4rem;
  text-decoration: none;
  cursor: pointer;
  margin-top: 3rem;
  display: inline-block;
  border-radius: .25rem;
  height: 4rem;
  padding:  0 3.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  background: #C22B00;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  @include tablet {
    margin-top: 5rem;
  }
}
