.modal {
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  position: fixed;
  padding: 10% 0;
  &__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(0,0,0,0.8);
    &__enter {
      opacity: 0;
    }
    &__enterActive {
      opacity: 1;
      transition: opacity 200ms ease-out;
    }
    &__exit {
      opacity: 1;
    }
    &__exitActive {
      opacity: 0;
      transition: opacity 200ms ease-out;
    }
  }
  &__container {
    z-index: 100;
    margin:  0 auto;
    position: relative;
    background: #fff;
    border-radius: .5rem;
    &__enter {
      opacity: 0;
      transform: scale(0);
    }
    &__enterActive {
      opacity: 1;
      transform: scale(1);
      transition: all 250ms ease-in;
    }
    &__exit {
      opacity: 1;
      transform: scale(1);
      transition: opacity 300ms ease-out, transform 300ms ease-out;
    }
    &__exitActive {
      opacity: 0;
      transform: scale(0);
    }

    &__size {
      &_default {
        max-width: 650px;
      }
      &_small {
        max-width: 500px;
      }
    }
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
  }
  &__title {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  &__subtitle {
    width: 100%;
    margin-top: 10px;
    white-space: pre-wrap;
  }
  &__close {
    cursor: pointer;
    background: none;
    top: 0;
    right: 0;
    position: absolute;
    svg {
      color: #333;
      font-size: 25px;
    }
  }
  &__content {
    display: block;
    position: relative;
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba(255, 255, 255, 0.7);
    &_icon {
      font-size: 80px;
    }
  }
}
