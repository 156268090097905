@import "./src/variable";

.input {
  color: #333;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  padding-left: 1.25rem;
  background: transparent;
  @include tablet {
    font-size: 1.4rem;
  }
  &::placeholder {
    font-size: 1.2rem;
    color: #AFAFAF;
    @include tablet {
      font-size: 1.3rem;
    }
  }
  &:focus {
    border: 2px solid #197323;
  }
  &:disabled {
    cursor: no-drop;
    background: #ebebeb;
  }

  &__container {
    width: 100%;
    height: 4rem;
    display: flex;
    overflow: hidden;
    background: #fff;
    border-radius: .5rem;
    border: 1px solid #D3D3D3;
    &_state {
      &_error {
        border-color: red;
        background: #ffeded;
      }
    }
  }
}
