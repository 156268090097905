@import "./src/variable";

.Footer {
  padding: 4rem 0;
  @include media();
  @include tablet {
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ul {
    li {
      list-style: none;
      display: inline-block;
      margin-right: 2rem;
      &:last-child {
        margin-right: 0;
      }
      a {
        text-decoration: none;
        font-size: 1.2rem;
        color: #202020;
        @include tablet {
          font-size: 1.3rem;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.Links {

}

.Logos {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 33rem;
  margin-top: 4rem;
  @include tablet {
    margin-top: 0;
  }
  p {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: .25rem;
  }
  span {
    position: relative;
    top: -.25rem;
    display: block;
    margin-left: 1.25rem;
    font-size: 1.2rem;
  }
  img {
    width: 10rem;
  }
}