.reset {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h1 {
    color: #197323;
    font-size: 2.1rem;
  }
  p {
    font-size: 1.4rem;
  }
}
