.prolong {
  cursor: pointer;
  font-size: 1.3rem;
  margin-top: .5rem;
  color: #FF7A00!important;
  text-decoration: underline;
}
.order {
  padding: 2rem 3rem 4rem;
  h1 {
    margin-bottom: 3rem;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding-bottom: .85rem;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 1.5rem;
    p {
      font-size: 1.4rem;
      &:first-child {
        font-weight: 600;
      }
    }
  }
  &__pay {
    display: flex;
    align-items: center;
    margin-top: 4rem;
    a {
      display: flex;
      align-items: center;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      border-radius: 0.25rem;
      height: 4.2rem;
      padding: 0 2rem;
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 600;
      background: #197323;
      box-shadow: 0px 2px 4px rgb(22 111 37 / 20%);
    }
    img {
      margin-left: 20px;
    }
  }
}
