@import "./src/variable";



.BlockOmnistar {
  margin-top: -7rem;
  @include tablet {
    display: flex;
    align-items: center;
    margin-top: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  div {
    &:last-child {
      margin-top: -7.5rem;
      @include tablet {
        padding-right: 5rem;
        margin-top: 0;
      }
    }
    h3 {
      color: #070707;
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 2rem;
      @include tablet {
        font-size: 2rem;
      }
    }
    p {
      color: #333;
      line-height: 130%;
      font-size: 1.3rem;
      margin-bottom: 1.4rem;
      @include tablet {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.BlockCenterPoint {
  background-size: cover;
  background-position: center center;
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div {
    &:first-child {
      width: 100%;
      @include tablet {
        width: 33.7rem;
      }
      img {
        width: 100%;
      }
    }
    &:last-child {
      margin-top: 2rem;
      @include tablet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-top: 0;
        width: calc(100% - 20em);
      }
      @include desktop {
        width: calc(100% - 35em);
      }
      @include wide {
        width: calc(100% - 45rem);
      }
      h2 {
        font-size: 1.5rem;
        line-height: 140%;
        margin-bottom: 2rem;
        @include tablet {
          font-size: 1.6rem;
          line-height: 130%;
        }
        @include desktop {
          font-size: 1.7rem;
        }
        @include wide {
          font-size: 2.1rem;
        }
        span {
          color: #197323;
        }
        br {
          display: none;
          @include desktop {
            display: block;
          }
        }
      }
      h3 {
        color: #333;
        margin-top: 1rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        b {
          color: #197323;
        }
      }
      p {
        color: #333;
        font-size: 1.3rem;
        line-height: 140%;
        @include tablet {
          font-size: 1.4rem;
          line-height: 150%;
        }
      }
    }
  }
}

.BlockTarifPlan {
  padding: 5rem 0 2rem;
  h2 {
    color: #070707;
    font-size: 2rem;
  }
}

.BlockTarifTabs {
  &Nav {
    display: flex;
    justify-content: space-between;
    width: calc(100% + .5rem);
    margin-left: -.25rem;
    position: relative;
    z-index: 100;
    overflow-x: scroll;
    @include tablet {
      overflow-x: hidden;
    }
  }
  &Button {
    min-width: 15rem;
    cursor: pointer;
    color: #333;
    flex: 1 1 auto;
    margin: 0 .5rem;
    display: block;
    flex-basis: 100%;
    background: #FFFFFF;
    border: 1px solid #DCDCDC;
    padding: .5rem 1rem;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: .5rem;
    @include tablet {
      padding: 1rem 1rem;
      font-size: 1.4rem;
    }
  }
  &Active {
    color: #fff;
    background: #197323;
    border: 1px solid #164C1C;
  }

  &Items {
    margin-top: 3rem;
    margin-bottom: 5rem;
    position: relative;
    overflow: hidden;
  }
  &Top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 80rem;
    div {
      width: 50%;
      margin-bottom: 2rem;
      @include tablet {
        width: auto;
        margin-bottom: 0;
      }
    }
    b {
      display: block;
      font-size: 1.3rem;
    }
    p {
      font-size: 1.4rem;
      margin-top: 1rem;
      @include tablet {
        margin-top: 2rem;
      }
    }
    img {
      margin-top: .25rem;
    }
  }
  &Image {
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include tablet {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
    img {
      width: 100%;
      @include tablet {
        width: auto;
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 150%;
      margin-bottom: 1.25rem;
      @include tablet {
        font-size: 1.4rem;
        padding-left: 2rem;
        margin-bottom: 1.5rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &Info {
    margin-top: 2.5rem;
    h3 {
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      @include tablet {
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.3rem;
      line-height: 150%;
      margin-bottom: 1.5rem;
      @include tablet {
        font-size: 1.4rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul {
      margin-top: 2rem;
      margin-left: 1.5rem;
      li {
        font-size: 1.4rem;
        list-style: none;
        position: relative;
        padding-left: 2rem;
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: .5rem;
          width: .5rem;
          height: .5rem;
          background: #2E9039;
          border-radius: 100%;
        }
      }
    }
  }
}

.ButtonLink {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  width: 27rem;
  height: 4.2rem;
  padding:  0 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  background: #197323;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  img {
    margin-left: 1.5rem;
    vertical-align: middle;
  }
}

.BitrixForm {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}

.BitrixButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  width: 24rem;
  height: 4.2rem;
  padding:  0 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  background: #197323;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}