.Banner {
  display: block;
  margin-left: 2rem;
  width: calc(100% - 69rem);
  a {
    display: block;
    img {
      width: 100%;
      display: block;
    }
  }
}