@import "./src/variable";

.Plans {
  margin-bottom: 4rem;
  @include tablet {
    margin-bottom: 6.5rem;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.Table {
  width: 100%;
  border-collapse: collapse;
  margin: 2rem 0 0 0;
  padding: 0;
  thead {
    td {
      top: 0;
      color: #fff;
      font-weight: 600;
      font-size: 1.4rem;
      position: sticky;
      z-index: 10;
      padding: 2rem 1.5rem;
      background: #197323;
      border: 1px solid #164c1c;
      border-top: none;
      border-bottom: 2px solid #164c1c;
    }
  }
  tbody {
    td {
      margin: 0;
      padding: 1.8rem 1.5rem;
      border: 1px solid #d0cfcf;
      color: #000;
      line-height: 140%;
      font-size: 1.4rem;
    }
    tr {
      &:nth-child(odd) {
        background-color: #f1f2f3;
      }
      &:nth-child(even) {
        background-color: #e7ecee;
      }
    }
  }
}

.Price,
.Name {
  font-weight: 800;
  color: #000;
}

.Brand {
  width: 340px;
}

.TableRTX {
  margin-bottom: 6.5rem;
}