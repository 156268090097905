@import "./src/variable";

.logout {
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 10;
  text-align: right;
  margin-top: .25rem;
  background: transparent;
  text-decoration: underline;
  @include tablet {
    color: red;
    margin-top: .5rem;
  }
  &:hover {
    text-decoration: none;
  }
}
