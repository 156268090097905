.row {
  display: flex;
  flex-direction: row;
  margin-left: -.5rem;
  width: calc(100% + 1rem);
  justify-content: space-between;
  > div {
    padding: 0 .5rem;
  }
}
