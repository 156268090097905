.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
  padding-left: 3rem;
  &:last-child {
    margin-bottom: 0;
  }
  &__input {
    position: absolute;
    transform: scale(0);
    &:checked ~ .checkbox__checkbox {
      background: #caeace;
      border: 1px solid #caeace;
    }
    &:checked ~ .checkbox__checkbox .checkbox__tick {
      display: block;
    }
  }
  &__label {
    user-select: none;
    font-size: 1.3rem;
    color: #333;
    line-height: 135%;
    a {
      color: #197323;
      text-decoration: underline;
    }
  }
  &__checkbox {
    display: grid;
    place-items: center;
    width: 21px;
    height: 21px;
    outline: none;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 2px;
    border: 1px solid #ced4da;
  }
  &__tick {
    display: none;
    width: 20px;
    img {
      width: 100%;
      left: 2px;
      top: -2px;
      position: relative;
    }
  }
  &__check {
    font-size: 19px;
    color: #fff;
  }
}
