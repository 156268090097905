.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 0.25rem;
  height: 4.2rem;
  padding: 0 4rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  &_disabled {
    pointer-events: none;
  }
  &_theme {
    &_default {
      color: #fff;
      height: 4.2rem;
      background: #197323;
      box-shadow: 0 2px 4px rgb(22 111 37 / 20%);
    }
    &_second {
      color: #197323;
      height: 3.6rem;
      font-size: 1.2rem;
      background: #a8d4ae;
    }
  }
}
