$tb: 768px;
$ds: 992px;
$wd: 1220px;


@mixin wide {
  @media (min-width: $wd) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $ds) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tb) {
    @content;
  }
}


@mixin media {
  width: 100%;
  margin:  0 auto;
  padding:  0 1.5rem;
  @include tablet {
    width: 76rem;
  }
  @include desktop {
    width: 92rem;
  }
  @include wide {
    width: 120rem;
  }
}
