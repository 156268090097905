input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pin {
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% / 4);
  justify-content: space-between;
  &:last-child {
    &:after {
      content: '';
    }
  }
  &:after {
    content: '-';
    right: 0;
    display: none;
    font-weight: 300;
    font-size: 2.4rem;
  }

  &__container {
    width: 100%;
    display: flex;
    column-gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 100%;
    height: 60px;
    outline: none;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    border-radius: .8rem;
    text-transform: uppercase;
    border: 1px solid #ced4da;
    user-select: none;
    &:not([value=""]) {
      background: #fff;
    }
    &:focus {
      border: 1px solid #197323;
    }
  }
}
