@import "./src/variable";

.Cabinet {

}

.Code {
    color: #7A7A7A;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: -1rem;
}


.Text {
  color: #7A7A7A;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: -1rem;
}

.Subtitle {
  margin-top: 2rem;
  color: #7A7A7A;
  font-size: 1.3rem;
  line-height: 2rem;
  @include tablet {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
}