@import "./src/variable";

.TableWrapper {
  width: 100%;
  overflow-x: auto;
  @include tablet {
     overflow: inherit;
  }
}

.Table {
  width: 97rem;
  border-collapse: collapse;
  padding: 0;
  margin-bottom: 2.5rem;
  @include tablet {
    width: 100%;
  }
  thead {
    border-bottom: 2px solid #E9E9E9;
    td {
      padding: 1.25rem 1.5rem;
      font-weight: 700;
      font-size: 1.2rem;
      color: #333;
      @include tablet {
        font-size: 1.3rem;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #E9E9E9;
    }
    td {
      margin: 0;
      padding: 2rem 1.5rem;
      color: #000;
      font-size: 1.3rem;
      @include tablet {
        font-size: 1.4rem;
      }
      &:last-child {
        max-width: 15rem;
      }
      h2 {
        font-size: 1.4rem;
        color: #077323;
        font-weight: 700;
        margin-bottom: .5rem;
        @include tablet {
          font-size: 1.6rem;
          margin-bottom: .75rem;
        }
      }
      h3 {
        font-size: 1.3rem;
        color: #333;
        font-weight: 700;
        margin-bottom: .75rem;
        @include tablet {
          font-size: 1.4rem;
        }
      }
      p {
        display: inline-block;
        text-align: left;
        font-size: 1.2rem;
        color: #333;
        @include tablet {
          font-size: 1.3rem;
        }
        &.Green {
          color: #077323;
        }
        &.Orange {
          color: #FF7A00;
        }
        &.Red {
          color: red;
        }
      }
      i {
        &.Green {
          display: inline-block;
          margin-right: .75rem;
          width: .7rem;
          height: .7rem;
          border-radius: 100%;
          background: #077323;
        }
        &.Orange {
          display: inline-block;
          margin-right: .75rem;
          width: .7rem;
          height: .7rem;
          border-radius: 100%;
          background: #FF7A00;
        }
      }
    }
  }
}

.Form {
  position: relative;
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  > div {
    width: 100%;
    @include tablet {
      width: 31%;
    }
  }
  > img {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 100;
  }
  > p {
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    @include tablet {
      font-size: 1.4rem;
      margin-bottom: 3rem;
    }
  }
}

.FormLoading {
  div {
    opacity: 0.1;
    pointer-events: none;
  }
  img {
    opacity: 1;
  }
}

.FormButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormSubmit {
  cursor: pointer;
  padding: 0 2.5rem;
  height: 4rem;
  background: #197323;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: .5rem;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}

.Wrapper {
  display: block;
  p {
    text-align: center;
  }
}

.Text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  p {
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.ButtonAdd {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  height: 4.2rem;
  padding:  0 2rem;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 600;
  background: #197323;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  img {
    margin-left: 1.5rem;
    vertical-align: middle;
  }
}

.ButtonProlong {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  height: 3.75rem;
  padding:  0 1.25rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  background: #197323;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  img {
    margin-left: 1.5rem;
    vertical-align: middle;
  }
}

.ButtonPay {
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  height: 3.75rem;
  padding:  0 1.25rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 600;
  background: #FF7A00;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  img {
    margin-left: 1.5rem;
    vertical-align: middle;
  }
}

.Password {
  width: 3rem;
  button {
    cursor: pointer;
    background: none;
    outline: none;
    img {
      width: 2.25rem;
    }
  }
}

.PasswordTooltip {
  font-size: 1.3rem;
  padding: 1.25rem!important;
  span {
    display: block;
    font-weight: 500;
  }
  ul {
    margin-top: 1rem;
    li {
      line-height: 140%;
      list-style: none;
    }
  }
}

.PasswordHide {
  pointer-events: none;
}

.InWork {
  text-align: center;
  font-size: 1.3rem;
  line-height: 140%;
  font-weight: bold;
  @include tablet {
    font-size: 1.4rem;
    line-height: 150%;
  }
  span {
    font-weight: normal;
    opacity: .5;
    display: block;
    font-size: 1.2rem;
    margin-top: 1rem;
    @include tablet {
      font-size: 1.3rem;
    }
  }
}

.DateButton {
  width: 100%;
  color: #333;
  height: 3.8rem;
  font-size: 1.4rem;
  font-weight: 600;
  background: #fff;
  padding-left: 1.25rem;
  border: 1px solid #D3D3D3;
  border-radius: .5rem;
  &:focus {
    outline: none;
    border: 1px solid #000;
  }
}

.DateButtonWrapper {
  position: relative;
  img {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
  }
}

.Wrapp {
  position: relative;
}

.Loader {

}


.Rinex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  p {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
  > div {
    width: 24%;
  }
}

.RinexButton {
  margin-top: 2rem;
  cursor: pointer;
  width: 100%;
  padding: 0 3.5rem;
  height: 3.8rem;
  background: #197323;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: .5rem;
}

.RinexDownload {
  cursor: pointer;
  padding: 0 4rem;
  height: 3.8rem;
  background: #197323;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: .5rem;
}


.RinexLoading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(255,255,255,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 50px;
    fill: #197323;
  }
  p {
    font-size: 13px;
  }
}

.RinexForm {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.RinexFinish {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  background: rgba(255,255,255,0.96);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 13px;
    margin-top: 1.5rem;
  }
  span {
    color: #197323;
    font-weight: bold;
  }
}

.RinexEmail {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255,255,255,0.96);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 13px;
    margin-top: 10px;
  }
}

.RinexEmailForm {
  display: flex;
  align-items: center;
  margin-top: 15px;
  div {
    width: 255px;
    margin-bottom: 0;
    margin-right: 2rem;
  }
}

.RinexNotice {
  font-size: 1.2rem;
  color: red;
}

.RinexError {
  margin-top: 2rem;
  width: 100% !important;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdd1d1;
  text-align: center;
  p {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}


.RinexLoading {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 50px;
    fill: #197323;
  }
}
