.iin {
  padding: 2rem 3rem 4rem;
  &__title {
    margin-bottom: 3rem;
    h1 {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
    h3 {
      font-weight: 400;
      font-size: 1.4rem;
    }
  }
  &__form {

  }
}
