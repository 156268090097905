@import "./src/variable";

.account {
  width: 95%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 25px;
  border-radius: 1rem;
  border: 1px solid #ebebeb;
  @include tablet {
    max-width: 1000px;
    padding: 20px 40px;
  }
  img {
    width: 20rem;
    display: block;
    margin:  0 auto;
    margin-bottom: 2rem;
    @include tablet {
      margin: 0;
    }
  }
  div {
    margin-bottom: 3rem;
  }
  h3 {
    color: #838383;
    font-weight: 300;
    text-align: center;
    margin-bottom: 3rem;
    @include tablet {
      text-align: right;
      margin-top: -4rem;
      margin-bottom: 0;
    }
    br {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }
  h1 {
    font-size: 2rem;
    @include tablet {
      font-size: 3rem;
    }
  }
  h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    @include tablet {
      font-size: 1.6rem;
    }
  }
  p {
    font-size: 1.3rem;
    line-height: 120%;
    margin-bottom: .5rem;
    @include tablet {
      font-size: 1.4rem;
    }
  }
}
