@import "./src/variable";

.link {
  outline: none;
  cursor: pointer;
  background: none;
  display: block;
  font-size: 1.4rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  padding-bottom: 1.2rem;
  text-decoration: none;
  @include desktop {
    font-size: 1.3rem;
  }
  @include wide {
    font-size: 1.4rem;
  }
  &_active {
    &:after {
      content: '';
      position: absolute;
      opacity: 1;
      left: 0;
      right: 0;
      bottom: -.2rem;
      width: 100%;
      height: .3rem;
      border-radius: .5rem;
      background: #C22B00;
    }
  }
}
