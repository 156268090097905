.navigator {
  height: 4rem;
  padding: .5rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  background: #ebebeb;
  border-radius: .5rem;
  &__container {
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
  }
  &__button {
    cursor: pointer;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: .5rem;
    font-size: 1.3rem;
    color: #333;
    z-index: 100;
    position: relative;
    font-weight: 600;
    background: transparent;
  }
  &__roller {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
    border-radius: .5rem;
    background: #9fd0a5;
    transition: .25s ease-in-out all;
  }
}
