.Table {
  width: 100%;
  border-collapse: collapse;
  margin: 2rem 0 0 0;
  padding: 0;
  thead {
    background: #197323;
    td {
      top: 0;
      color: #fff;
      font-weight: 600;
      font-size: 1.4rem;
      position: sticky;
      z-index: 10;
      padding: 2rem 1.5rem;
      background: #197323;
      border: 1px solid #164c1c;
      border-top: none;
      border-bottom: 2px solid #164c1c;
    }
  }
  tbody {
    td {
      margin: 0;
      padding: 2rem 1.5rem;
      border: 1px solid #000;
      color: #000;
      font-size: 1.4rem;
    }
  }
}

.Button {
  width: 19rem;
  text-align: center;
  button {
    outline: none;
    cursor: pointer;
    display: inline-block;
    background: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: #197323;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.Online {
  display: inline-block;
  width: .8rem;
  height: .8rem;
  background: #2E9039;
  border-radius: 100%;
}

.Offline {
  display: inline-block;
  width: .8rem;
  height: .8rem;
  background: #BE4906;
  border-radius: 100%;
}

.Status {
  width: 15rem;
}

.TableHint {
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: 1rem 0;
}