@import "./src/variable";

.label {
  width:  100%;
  color: #333;
  cursor: pointer;
  display: block;
  font-size: 1.3rem;
  margin-bottom: .35rem;
  @include tablet {
    font-size: 1.4rem;
  }
}
