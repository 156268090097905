@import "./src/variable";

.progress {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    background: #F2F2F2;
    @include tablet {
        padding: 2rem 3rem;
    }

    &__item {
        width: 47%;
        opacity: .3;
        @include tablet {
            width: 45%;
        }
        h2 {
            font-size: 1.6rem;
            color: #197323;
            @include tablet {
                font-size: 1.8rem;
            }
        }
        p {
            font-size: 1.2rem;
            color: #333;
            margin: 1rem 0;
            @include tablet {
                font-size: 1.3rem;
            }
        }
        span {
            display: block;
            width: 100%;
            background: #888;
            height: .4rem;
        }
        &_active {
            opacity: 1;
            span {
                background: #197323;
            }
        }
    }
}
