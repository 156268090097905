@import "./src/variable";

.Button {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  border-radius: .5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
}

.red {
  background: #C22B00;
}

.green {
  background: #197323;
}

.DemoButton {
  display: none;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  width: 15rem;
  height: 3.75rem;
  line-height: 3.75rem;
  border: none;
  color: #fff;
  background: #C22B00;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  border-radius: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  @include tablet {
    display: block;
  }
  @include desktop {
    width: 13rem;
    font-size: 1.1rem;
  }
  @include wide {
    width: 15rem;
    font-size: 1.2rem;
  }
}


.AuthButton {
  position: relative;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  background: none;
  &:after {
    bottom: .25rem!important;
  }
  p {
    color: #fff;
    position: relative;
    z-index: 10;
    text-align: right;
    margin-top: .25rem;
    text-decoration: underline;
    @include tablet {
      color: red;
      margin-top: .5rem;
    }
  }
  img {
    vertical-align: middle;
    margin-right: .75rem;
  }
  span {
    font-size: 1.2rem;
    vertical-align: middle;
    @include tablet {
      font-size: 1.3rem;
    }
  }
}

.AuthButtonWhite {
  span {
    color: #fff;
  }
}

.AuthButtonDark {
  span {
    color: #333;
  }
}

.AuthButtonActive {
  &:after {
    content: '';
    position: absolute;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: -1rem;
    width: 50%;
    height: .3rem;
    border-radius: .5rem;
    background: #C22B00;
  }
}