@import "./src/variable";

.Desc {
  margin-top: 2rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #646464;
  @include tablet {
    margin-top: 3rem;
  }
  @include tablet {
    padding-bottom: 5rem;
  }
  p {
    font-size: 1.3rem;
    margin: 0 0 1rem 0;
    color: #333;
    @include tablet {
      font-size: 1.5rem;
    }
    b {
      font-weight: 600;
    }
  }
  a {
    color: #227831;
    font-weight: bold;
    text-decoration: none;
  }
  ul {
    margin-top: .75rem;
    margin-bottom: 2rem;
    li {
      list-style: none;
      position: relative;
      font-size: 1.3rem;
      padding-left: 1.25rem;
      margin-bottom: .5rem;
      @include tablet {
        font-size: 1.4rem;
        padding-left: 1.5rem;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: .5rem;
        width: .5rem;
        height: .5rem;
        border-radius: 100%;
        background: #197323;
      }
    }
  }
}

.Pdf {
  margin-top: 3rem;
}

.Item {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
  text-decoration: none;
  img {
    margin-right: 1.5rem;
    width: 2.5rem;
    @include tablet {
      width: auto;
      margin-right: 2rem;
    }
  }
  span {
    color: #333;
    font-size: 1.3rem;
    font-weight: 600;
    @include tablet {
      font-size: 1.4rem;
    }
  }
}