@import "./src/variable";

.finish {
  padding: 6rem 2rem;
  @include tablet {
    padding: 4rem 2rem;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h1 {
      color: #333;
      font-size: 1.8rem;
      margin-top: 2.5rem;
      white-space: pre-line;
      @include tablet {
        font-size: 2.4rem;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 140%;
      white-space: pre-line;
      margin: 1.5rem 0 3rem;
    }

    span {
      display: block;
      margin-top: 1rem;
      color: #9a4343;
      font-size: 1.2rem;
    }

    button {
      padding: 1.25rem 3rem;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 600;
      cursor: pointer;
      background: #197323;
      box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
      border-radius: 5px;
    }
  }
}
