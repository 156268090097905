@import "./src/variable";

.Wrapper {
  margin-top: 5rem;
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}


.Map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30rem;
  overflow: hidden;
  border: 2px solid #197323;
  @include tablet {
    height: 50rem;
  }
}



.Title {
  @include tablet {
    display: flex;
    align-items: center;
  }
  h2 {
    font-size: 2.1rem;
    color: #197323;
    @include tablet {
      font-size: 2.6rem;
    }
  }
}

.Online {
  display: inline-block;
  position: relative;
  top: .25rem;
  padding-left: 1.5rem;
  color: #000;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 1.5rem;
  @include tablet {
    margin-top: 0;
    font-size: 1.4rem;
    padding-left: 1.75rem;
    margin-left: 6.5rem;
  }
  &:after {
    content: '';
    display: block;
    left: 0;
    top: .5rem;
    position: absolute;
    width: .6rem;
    height: .6rem;
    border-radius: 100%;
    background: #077323;
    @include tablet {
      top: .35rem;
      width: .8rem;
      height: .8rem;
    }
  }
}
.Offline {
  position: relative;
  top: .25rem;
  padding-left: 1.5rem;
  margin-left: 2rem;
  color: #000;
  font-size: 1.3rem;
  font-weight: 600;
  margin-top: 1.5rem;
  @include tablet {
    margin-top: 0;
    font-size: 1.4rem;
    padding-left: 1.75rem;
    margin-left: 3rem;
  }
  &:after {
    content: '';
    display: block;
    left: 0;
    top: .5rem;
    position: absolute;
    width: .6rem;
    height: .6rem;
    border-radius: 100%;
    background: #BE4906;
    @include tablet {
      top: .35rem;
      width: .8rem;
      height: .8rem;
    }
  }
}

.Form {
  background: #E7ECEE;
  padding: 2.5rem 1.5rem;
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.5rem 2.5rem;
  }
  h2 {
    font-size: 1.6rem;
    @include tablet {
      font-size: 2rem;
    }
  }
  p {
    margin-top: .75rem;
    font-size: 1.4rem;
    color: #616161;
    @include tablet {
      font-size: 1.6rem;
    }
  }
}

.Button {
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
  border-radius: .25rem;
  height: 4.5rem;
  padding:  0 1.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  background: #197323;
  margin-top: 2rem;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  @include tablet {
    margin-top: 0;
    font-size: 1.4rem;
    height: 4.8rem;
    padding:  0 2.5rem;
  }
  img {
    margin-left: 1.5rem;
    vertical-align: middle;
  }
}
