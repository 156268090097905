@import "./src/variable";


.Menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index:  999;
  opacity: 0;
  padding:  0 1.5rem;
  padding-top: 12rem;
  pointer-events: none;
  background: #fff;
  transition: all 0.4s ease-in-out;
  ul {
    li {
      text-align: left;
      list-style: none;
    }
  }
}

.MenuActive {
  opacity: 1;
  pointer-events: all;
  transition: all 0.4s ease-in-out;
}

.Link {
  outline: none;
  cursor: pointer;
  background: none;
  display: inline-block;
  font-size: 1.6rem;
  padding: 2rem 0;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-bottom: 1.2rem;
  text-decoration: none;
}

.LinkActive {
  &:after {
    content: '';
    position: absolute;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: .5rem;
    width: 100%;
    height: .3rem;
    border-radius: .5rem;
    background: #C22B00;
  }
}