.option {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.switcher {
  display: flex;
  cursor: pointer;
  margin-bottom: 2rem;
}

.switcherBox {
  width: 25px;
  height: 25px;
  position: relative;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  img {
    width: 16px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.switcherTitle {
  padding-left: 1rem;
  p {
    font-weight: 600;
    font-size: 1.4rem;
  }
  span {
    display: block;
    font-weight: 300;
    margin-top: .2rem;
    font-size: 1.2rem;
  }
}
