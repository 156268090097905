@import "./src/variable";

.error {
  color: red;
  width:  100%;
  display: block;
  padding-top: .5rem;
  font-size: 1.2rem;
  @include tablet {
    font-size: 1.2rem;
  }
}
