@import "./src/variable";

.Tabs {
  margin-top: 2.5rem;
}
.Nav {
  display: flex;
}

.Link {
  position: relative;
  z-index: 10;
  cursor: pointer;
  outline: none;
  height: 0;
  color: #333;
  width: 25rem;
  background: none;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 1.6rem;
  border-bottom: 5rem solid #B8B8B8;
  border-right: 3rem solid transparent;
  margin-left: -3rem;
  padding-left: 2rem;
  @include tablet {
    font-size: 1.5rem;
    line-height: 5rem;
  }
  &:nth-child(1) {
    z-index: 12;
    padding-left: .5rem;
    border-bottom: 5rem solid #D1D1D1;
    margin-left: 0;

  }
  &:nth-child(2) {
    z-index: 11;
    border-bottom: 5rem solid #B8B8B8;
  }
  &:nth-child(3) {
    z-index: 10;
    border-bottom: 5rem solid #D1D1D1;
  }

  span {
    position: relative;
    top: .75rem;
    line-height: 1.6rem;
    @include tablet {
      top: 0;
    }
  }
}

.LinkActive {
  color: #fff;
  border-bottom: 5rem solid #197323!important;
}

.Wrapper {
  border: 1px solid #197323;
  padding: 2.5rem;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}


