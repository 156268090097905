@import "./src/variable";

.request {
  &__container {
    padding: 40px;
  }
  &__buttons {
    margin-top: 2.5rem;
  }
  &__title {
    margin-bottom: 2rem;
    h1 {
      margin: 0;
      color: #197323;
      font-size: 2rem;
      margin-bottom: .5rem;
      @include tablet {
        font-size: 2.4rem;
      }
    }
    img {
      margin:  0 auto;
    }
    p {
      color: #333;
      font-size: 1.3rem;
      line-height: 130%;
      white-space: pre-wrap;
      @include tablet {
        font-size: 1.4rem;
      }
    }
  }
}
