@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

* {
    border: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
    font-weight: 400;
}

.wrapper {
    width: 1200px;
    margin:  0 auto;
    padding:  1rem 0;
}



h1.title {
    font-size: 1.8rem;
    font-weight: 800;
    color: #197323;
    position: relative;
    z-index: 110;
}

@media (min-width: 48em) {
    h1.title {
        font-size: 2.4rem;
    }
}
@media (min-width: 62em) {
    h1.title {
        font-size: 2.4rem;
    }
}
@media (min-width: 75em) {
    h1.title {
        font-size: 2.4rem;
    }
}
@media (min-width: 87.5em) {
    h1.title {
        font-size: 2.4rem;
    }
}

h2.title {
    font-size: 1.7rem;
    font-weight: 800;
    color: #197323;
    position: relative;
    z-index: 110;
}

@media (min-width: 48em) {
    h2.title {
        font-size: 2.1rem;
    }
}
@media (min-width: 62em) {
    h2.title {
        font-size: 2.1rem;
    }
}
@media (min-width: 75em) {
    h2.title {
        font-size: 2.1rem;
    }
}
@media (min-width: 87.5em) {
    h2.title {
        font-size: 2.1rem;
    }
}
.react-datepicker-time__header {
    text-overflow: ellipsis !important;
    white-space: pre-wrap !important;
}

.select__placeholder  {
    font-weight: 600;
    font-size: 1.3rem;
    color: #b5b5b5!important;
}
.select__menu {
    margin: 0!important;
    z-index: 150!important;
}

.select__option  {
    font-size: 1.4rem!important;
    font-weight: 600;
    color: #333;
}

.select__option--is-focused {
    background-color: #caeace!important;
}

.select__control--is-focused {
    border-color: #333!important;
    border: 1px solid!important;
    box-shadow: none!important;
}

.select__option--is-selected {
    background-color: #197323!important;
}

.select__single-value,
.select__input input {
    font-size: 1.4rem!important;
    font-weight: 600!important;
    color: #333!important;
}
.react-datepicker {
    font-size: 1rem;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #197323;
}
.react-datepicker-popper {
    z-index: 1000;
}

.react-datepicker__input-container input::placeholder {
    color: #b5b5b5!important
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    position: relative;
    font-size: 1.2rem;
    padding: .5rem 0;
    top: -2px;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
    margin: 0.2rem;
    font-size: 1.1rem;
}
.react-datepicker__day-name {
    font-weight: 600;
}

.react-datepicker__navigation {
    outline: none;
}
.react-datepicker__navigation--next {
    height: 12px;
    width: 12px;
    border: 0.65rem solid transparent;
    border-left-color: #197323;
}

.react-datepicker__navigation--previous {
    height: 12px;
    width: 12px;
    border: 0.65rem solid transparent;
    border-right-color: #197323;
}

.react-datepicker__navigation--next:hover{
    border-left-color: #197323;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #197323;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #197323;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    background-color: #197323;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #197323;
}

.react-datepicker__day--disabled {
    cursor: no-drop;
}
