@import "./src/variable";

.Header {
  width: 100%;
  position: relative;
  z-index: 1000;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media();
}
.TomMobile {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #197323;
  padding:  0 1.5rem;
  @include tablet {
    display: none;
  }
  span,
  a {
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
  }
}
.Top {
  display: none;
  @include tablet {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #197323;
  }
  span,
  a {
    color: #fff;
    font-size: 1.3rem;
    text-decoration: none;
  }
}

.Call {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include tablet {
    width: auto;
  }
  span {
    margin-right: .75rem;
  }

  a {
    display: inline-block;
  }
}

.Item {
  display: flex;
  align-items: center;
  @include tablet {
    margin-right: 3rem;
  }
  &:last-child {
    margin-right: 0;
  }
}

.CallCenter {
  display: none;
  @include tablet {
    display: inline-block;
  }
}

.Messenger {
  display: flex;
  align-items: center;
  a {
    display: inline-block;
    margin-right: .75rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.Links {
  display: none;
  @include desktop {
    display: flex;
    align-items: center;
  }
  a {
    display: inline-block;
    margin-right: 3rem;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.Online {
  background: #2E9039;
  font-weight: 600;
  border-radius: .5rem;
  padding: .5rem .75rem;
}

.Icons {
  margin-right: .75rem;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 2rem;
    @include tablet {
      width: auto;
    }
  }
}

.Bottom {
  width: 100%;
  padding: 1rem 0;
  @include tablet {
    display: block;
    padding: 2rem 0;
  }
}

.Menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  > a {
    @include tablet {
      width: 23.5rem;
    }
    @include desktop {
      width: 19rem;
    }
    @include wide {
      width: 23.5rem;
    }
    img {
      width: 16rem;
      display: block;
      @include tablet {
        width: 20rem;
      }
      @include desktop {
        width: 18rem;
      }
      @include wide {
        width: 20rem;
      }
    }
  }

  ul {
    display: none;
    @include tablet {
      display: block;
      margin-left: 3rem;
      border-bottom: 1px solid #646464;
    }
    @include desktop {
      margin-left: 1.5rem;
    }
    @include wide {
      margin-left: 3rem;
    }
    li {
      display: inline-block;
      margin-right: 3rem;
      list-style: none;
      @include desktop {
        margin-right: 1.5rem;
      }
      @include wide {
        margin-right: 3rem;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.Controls {
  display: none;
  @include tablet {
    width: 32rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  a {
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
    }
  }
  button {
    @include tablet {
      margin-right: 2rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.Burger {
  display: block;
  @include tablet {
    display: none;
  }
}

.Demo {
  outline: none;
  cursor: pointer;
  width: 15rem;
  height: 3.75rem;
  border: none;
  color: #fff;
  background: #C22B00;
  box-shadow: 0px 2px 4px rgba(22, 111, 37, 0.2);
  border-radius: 5px;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
}



.link {
  outline: none;
  cursor: pointer;
  background: none;
  display: block;
  font-size: 1.4rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  padding-bottom: 1.2rem;
  text-decoration: none;
  @include desktop {
    font-size: 1.3rem;
  }
  @include wide {
    font-size: 1.4rem;
  }
}
