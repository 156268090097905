@import "./src/variable";

.wrapper {
  &__container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 50px 40px;
    border-radius: 1rem;
    border: 1px solid #ebebeb;
  }
  &__form {
    margin-top: 2rem;
  }
  &__title {
    h1 {
      margin: 0;
      color: #197323;
      font-size: 2rem;
      margin-bottom: .5rem;
      @include tablet {
        font-size: 2.4rem;
      }
    }
    img {
      margin:  0 auto;
    }
    p {
      color: #333;
      font-size: 1.3rem;
      line-height: 130%;
      white-space: pre-wrap;
      @include tablet {
        font-size: 1.4rem;
      }
    }
  }
}
