.pay {
  display: block;
  &__container {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
  &__group {
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
  }
  &__item {
    cursor: pointer;
    height: 4rem;
    display: flex;
    color: #333;
    line-height: 135%;
    font-size: 1.3rem;
    font-weight: 600;
    align-items: center;
    width: calc(50% - 1rem);
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #D3D3D3;
    &_active {
      border: 2px solid #197323;
    }
    img {
      margin: 0;
      margin-right: 2rem;
    }
  }
}
