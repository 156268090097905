.auth {
    &__buttons {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        justify-content: space-between;
    }
    &__reset {
        cursor: pointer;
        color: #c22b00;
        background: none;
        font-size: 1.3rem;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &__register {
        cursor: pointer;
        color: #197323;
        background: none;
        font-size: 1.3rem;
        font-weight: 600;
        margin-top: 2rem;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
