@import "./src/variable";

.Main {
  width: 100%;
  position: relative;
}

.Wrapper {
  min-height: calc(100vh - 13.7rem - 9.4rem);
  @include media();
  padding: 2rem 1.5rem;
}

.Loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
